var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "qor" }, [
    _c("div", { staticClass: "qor__supplier" }, [
      _c("figure", { staticClass: "qor__supplier-img" }, [
        _c("img", {
          attrs: {
            src: _vm.imagePath(
              "images/" + _vm.quotation.supplier.coverPhoto,
              960,
              250
            ),
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "qor__supplier-info" }, [
        _c("strong", { staticClass: "qor__supplier-title" }, [
          _vm._v(_vm._s(_vm.quotation.supplier.title)),
        ]),
        _vm._v(" "),
        _vm.quotation.supplier.men
          ? _c("span", {
              staticClass: "qor__supplier-icon",
              domProps: { innerHTML: _vm._s(_vm.svg.men) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.quotation.supplier.ladies
          ? _c("span", {
              staticClass: "qor__supplier-icon",
              domProps: { innerHTML: _vm._s(_vm.svg.ladies) },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "qor__info qor__info--grid" }, [
        _c("div", { staticClass: "qor__info-cell" }, [
          _c("div", { staticClass: "qor__info-label" }, [_vm._v("Country")]),
          _vm._v(" "),
          _c("div", { staticClass: "qor__info-text" }, [
            _vm._v(_vm._s(_vm.quotation.supplier.country)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "qor__info-cell" }, [
          _c("div", { staticClass: "qor__info-label" }, [_vm._v("Website")]),
          _vm._v(" "),
          _c("div", { staticClass: "qor__info-text" }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm._f("fixHref")(_vm.quotation.supplier.web),
                  target: "_blank",
                  title: _vm.quotation.supplier.web,
                },
              },
              [
                _vm._v(
                  _vm._s(_vm._f("urlHideProtocol")(_vm.quotation.supplier.web))
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "qor__info-cell" }, [
          _c("div", { staticClass: "qor__info-label" }, [_vm._v("Structure")]),
          _vm._v(" "),
          _c("div", { staticClass: "qor__info-text" }, [
            _vm._v(
              _vm._s(
                _vm.quotation.supplier.companyStructure == 0
                  ? "Manufacturer"
                  : "Agent"
              )
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "qor__info" }, [
        _c("div", { staticClass: "qor__info-label" }, [
          _vm._v("Other Brands Producing"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "qor__info-text",
            attrs: { title: _vm.quotation.otherBrandsProducing },
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.quotation.otherBrandsProducing || "—") +
                "\n      "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      !_vm.fromAdminApp
        ? _c(
            "div",
            { staticClass: "qor__info qor__info--center" },
            [
              _c(
                "router-link",
                {
                  staticClass: "qor__moreInfo",
                  attrs: {
                    to: {
                      name: "supplier",
                      params: { name: _vm.quotation.supplier.slug },
                    },
                  },
                },
                [_vm._v("More info")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "qor__details" }, [
      _c("div", { staticClass: "qor__info" }, [
        _c("div", { staticClass: "qor__info-label" }, [
          _vm._v("Message from Company"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "qor__message" }, [
          _vm._v(_vm._s(_vm.quotation.messageFromSupplier)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "qor__info" }, [
        _c("div", { staticClass: "qor__info-label" }, [
          _vm._v("FindSourcing Comment"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "qor__message" }, [
          _vm._v(_vm._s(_vm.quotation.messageFromFindSourcing)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "qor__info qor__info--grid" }, [
        _c("div", { staticClass: "qor__info-cell" }, [
          _c("div", { staticClass: "qor__info-label qor__info-label--h" }, [
            _vm._v("Sample L/T Correct material"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qor__info-text qor__info-text--l" }, [
            _vm._v(_vm._s(_vm.quotation.sampleLTCorrectMaterial)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "qor__info-cell" }, [
          _c("div", { staticClass: "qor__info-label qor__info-label--h" }, [
            _vm._v("Production L/T"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qor__info-text qor__info-text--l" }, [
            _vm._v(_vm._s(_vm.quotation.productionLT)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "qor__info-cell" }, [
          _c("div", { staticClass: "qor__info-label qor__info-label--h" }, [
            _vm._v("Price (FOB/FCA)"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qor__info-text qor__info-text--l" }, [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.quotation.priceFOBFCA
                    ? (_vm.quotation.currency || {}).symbol +
                        _vm.quotation.priceFOBFCA
                    : "—"
                ) +
                "\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "qor__info-cell" }, [
          _c("div", { staticClass: "qor__info-label qor__info-label--h" }, [
            _vm._v("Sample L/T Available material"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qor__info-text qor__info-text--l" }, [
            _vm._v(_vm._s(_vm.quotation.sampleLTAvailableMaterial)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "qor__info-cell" }, [
          _c("div", { staticClass: "qor__info-label qor__info-label--h" }, [
            _vm._v("Minimum order quantity"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qor__info-text qor__info-text--l" }, [
            _vm._v(_vm._s(_vm.quotation.moq)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "qor__info-cell" }, [
          _c("div", { staticClass: "qor__info-label qor__info-label--h" }, [
            _vm._v("Sample Cost"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qor__info-text qor__info-text--l" }, [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.quotation.sampleCost
                    ? (_vm.quotation.currency || {}).symbol +
                        _vm.quotation.sampleCost
                    : "—"
                ) +
                "\n        "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.quotation.images && _vm.quotation.images.length
        ? _c(
            "div",
            { staticClass: "qor__info" },
            [
              _c("strong", [_vm._v("Pictures")]),
              _vm._v(" "),
              _vm._l(_vm.quotation.images, function (image, i) {
                return _c("div", { key: i, staticClass: "quotation__file" }, [
                  _c(
                    "a",
                    {
                      staticClass: "quotation__file-link",
                      attrs: {
                        href: _vm.$blobUrl + "/" + image.url,
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(image.url.split("/")[1]) +
                          "\n        "
                      ),
                    ]
                  ),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "qor__info" }, [
        _c("strong", [_vm._v("Cost Breakdown")]),
        _vm._v(" "),
        _c("div", { staticClass: "qor__pricetable" }, [
          _c("div", { staticClass: "qor__pricetable-row" }, [
            _c("span", [_vm._v("Materials")]),
            _vm._v(" "),
            _c("i"),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.quotation.cbMaterials
                    ? (_vm.quotation.currency || {}).symbol +
                        _vm.quotation.cbMaterials
                    : "—"
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qor__pricetable-row" }, [
            _c("span", [_vm._v("Components")]),
            _vm._v(" "),
            _c("i"),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.quotation.cbComponents
                    ? (_vm.quotation.currency || {}).symbol +
                        _vm.quotation.cbComponents
                    : "—"
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qor__pricetable-row" }, [
            _c("span", [_vm._v("Labelling & Packaging")]),
            _vm._v(" "),
            _c("i"),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.quotation.cbLabellingPackaging
                    ? (_vm.quotation.currency || {}).symbol +
                        _vm.quotation.cbLabellingPackaging
                    : "—"
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qor__pricetable-row" }, [
            _c("span", [_vm._v("Tooling")]),
            _vm._v(" "),
            _c("i"),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.quotation.cbTooling
                    ? (_vm.quotation.currency || {}).symbol +
                        _vm.quotation.cbTooling
                    : "—"
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qor__pricetable-row" }, [
            _c("span", [_vm._v("Labor costs")]),
            _vm._v(" "),
            _c("i"),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.quotation.cbLaborCosts
                    ? (_vm.quotation.currency || {}).symbol +
                        _vm.quotation.cbLaborCosts
                    : "—"
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qor__pricetable-row" }, [
            _c("span", [_vm._v("Overheads")]),
            _vm._v(" "),
            _c("i"),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.quotation.cbOverheads
                    ? (_vm.quotation.currency || {}).symbol +
                        _vm.quotation.cbOverheads
                    : "—"
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qor__pricetable-row" }, [
            _c("span", [_vm._v("Profit")]),
            _vm._v(" "),
            _c("i"),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.quotation.cbProfit
                    ? (_vm.quotation.currency || {}).symbol +
                        _vm.quotation.cbProfit
                    : "—"
                )
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      !_vm.fromAdminApp
        ? _c("div", { staticClass: "qor__info" }, [
            _vm.quotation.response == null
              ? _c("strong", [_vm._v("Next Step")])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "qor__response" }, [
              _vm.quotation.response == null
                ? _c("p", [
                    _vm._v("\n          How do you want to proceed. "),
                    _c("br"),
                    _vm._v(
                      "On positive response you´ll be sent the contact details and conversation\n          history.\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("button", {
                class: {
                  green:
                    _vm.quotation.response == true && !_vm.quotationDeclined,
                },
                domProps: { innerHTML: _vm._s(_vm.svg.like) },
                on: { click: _vm.acceptQuotation },
              }),
              _vm._v(" "),
              _c("button", {
                class: {
                  red: _vm.quotation.response == false || _vm.quotationDeclined,
                },
                domProps: { innerHTML: _vm._s(_vm.svg.dislike) },
                on: { click: _vm.decline },
              }),
            ]),
            _vm._v(" "),
            _vm.quotationDeclined
              ? _c(
                  "form",
                  {
                    staticClass: "qor__decline",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.declineQuotation($event)
                      },
                    },
                  },
                  [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.quotation.messageFromClient,
                          expression: "quotation.messageFromClient",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "qor__decline-textarea",
                      attrs: {
                        placeholder:
                          "Please state what is not attractive with this option. Your comment will be used to improve quality for your future quotations.",
                      },
                      domProps: { value: _vm.quotation.messageFromClient },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotation,
                            "messageFromClient",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "fs-button-main",
                      {
                        staticClass: "qor__decline-cancel",
                        attrs: { type: "button" },
                        on: { click: _vm.cancelDecline },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _vm._v(" "),
                    _c(
                      "fs-button-main",
                      {
                        staticClass: "qor__decline-submit",
                        attrs: { type: "submit" },
                      },
                      [_vm._v("Submit")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.fromAdminApp
        ? _c("div", { staticClass: "qor__info" }, [
            _c("div", { staticClass: "qor__status" }, [
              _c(
                "div",
                { staticClass: "qor__status-updated qor__info-label" },
                [
                  _vm._v("\n          Response Updated\n          "),
                  _c("span", { staticClass: "qor__info-label--dark" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("dateformat")(
                          _vm.quotation.clientsResponseUpdatedAt,
                          "MM/dd/yyyy 'at' HH:mm"
                        )
                      )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "qor__status-icons" }, [
                _c("div", {
                  staticClass: "qor__status-icon",
                  class: {
                    green:
                      _vm.quotation.response == true && !_vm.quotationDeclined,
                  },
                  domProps: { innerHTML: _vm._s(_vm.svg.like) },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "qor__status-icon",
                  class: {
                    red:
                      _vm.quotation.response == false || _vm.quotationDeclined,
                  },
                  domProps: { innerHTML: _vm._s(_vm.svg.dislike) },
                }),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.fromAdminApp && _vm.quotation.response == false
        ? _c("div", { staticClass: "qor__info" }, [
            _vm._v(_vm._s(_vm.quotation.messageFromClient)),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }