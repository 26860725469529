<template>
  <section class="page">
    <h1 class="page__heading">Quotation Overview</h1>
    <p class="post-content__highlighted post-content__highlighted--small" v-if="$store.user.isBuyer">
      Below you can see your pending quotation requests and make new ones.
    </p>
    <p class="post-content__highlighted post-content__highlighted--small" v-if="$store.user.isSupplier">
      Below you can see the quotations you have submitted with possibility to update them.
    </p>
    <p v-if="$store.user.isBuyer">
      <router-link :to="{ name: 'quotationForm' }" class="qo__button old-form__button">Make new quotation</router-link>
    </p>

    <h2
      class="page__subheading post-content__subheading post-content__subheading--center"
      v-if="quotationRequestsWithoutQuotation.length"
    >
      New quotation requests
    </h2>
    <h2
      class="page__subheading post-content__subheading post-content__subheading--center"
      v-if="!quotationRequests.length"
    >
      No Quotations Pending
    </h2>
    <div class="qo-list" v-if="quotationRequestsWithoutQuotation.length">
      <div
        class="qo-item"
        v-for="(qr, i) in quotationRequestsWithoutQuotation"
        :key="i"
        :id="'qoItem' + qr.id"
        :class="{ active: detailsData && detailsData.id == qr.id }"
      >
        <div class="qo-item__main" @click="gotoIntro(qr)">
          <div class="qo-item__img" v-if="qr.productPicture">
            <img :src="$blobUrl + '/' + qr.productPicture" alt="Product Image" />
          </div>
          <div class="qo-item__img qo-item__img--placeholder" v-else></div>
          <div class="qo-item__main-content">
            <div class="qo-item__name">
              <div class="qo-item__label">Product Name</div>
              <div class="qo-item__text">{{ qr.productName }}</div>
            </div>
            <div class="qo-item__name" v-if="$store.user.isSupplier">
              <div class="qo-item__label">Brand</div>
              <div class="qo-item__text">{{ qr.company.name }}</div>
            </div>
            <div class="qo-item__date">
              <div class="qo-item__label">Send out Date</div>
              <div class="qo-item__text">{{ qr.createdAt | dateformat('MM/dd/yyyy') }}</div>
            </div>
            <div class="qo-item__current-stage">
              <div class="qo-item__label">Current Stage</div>
              <div class="qo-item__text">
                {{
                  [
                    'Quotation Submitted',
                    'Global Sourcing',
                    'Suppliers Prepare Offers',
                    'FindSourcing Controlling Offers',
                    'Deal Selection',
                  ][qr.currentStage - 1]
                }}
              </div>
            </div>
            <div class="qo-item__stages">
              <div
                class="qo-item__stage"
                :class="{ 'qo-item__stage--current': qr.currentStage == 1 }"
                title="Quotation Submitted"
              >
                <img src="../img/quotation/s01.png" alt="Quotation Submitted" />
              </div>
              <div class="qo-item__stage-arrow"></div>
              <div
                class="qo-item__stage"
                :class="{ 'qo-item__stage--current': qr.currentStage == 2 }"
                title="Global Sourcing"
              >
                <img src="../img/quotation/s02.png" alt="Global Sourcing" />
              </div>
              <div class="qo-item__stage-arrow"></div>
              <div
                class="qo-item__stage"
                :class="{ 'qo-item__stage--current': qr.currentStage == 3 }"
                title="Suppliers Prepare Offers"
              >
                <img src="../img/quotation/s03.png" alt="Suppliers Prepare Offers" />
              </div>
              <div class="qo-item__stage-arrow"></div>
              <div
                class="qo-item__stage"
                :class="{ 'qo-item__stage--current': qr.currentStage == 4 }"
                title="FindSourcing Controlling Offers"
              >
                <img src="../img/quotation/s04.png" alt="FindSourcing Controlling Offers" />
              </div>
              <div class="qo-item__stage-arrow"></div>
              <div
                class="qo-item__stage"
                :class="{ 'qo-item__stage--current': qr.currentStage == 5 }"
                title="Deal Selection"
              >
                <img src="../img/quotation/s05.png" alt="Deal Selection" />
              </div>
            </div>
          </div>
          <div class="qo-item__arrow qo-item__arrow--right"></div>
        </div>
      </div>
    </div>
    <h2
      class="page__subheading post-content__subheading post-content__subheading--center"
      v-if="quotationRequestsWithQuotation.length"
    >
      Submitted quotations
    </h2>
    <div class="qo-list" v-if="quotationRequestsWithQuotation.length">
      <div
        class="qo-item"
        v-for="(qr, i) in quotationRequestsWithQuotation"
        :key="i"
        :id="'qoItem' + qr.id"
        :class="{ active: detailsData && detailsData.id == qr.id }"
      >
        <div class="qo-item__main" @click="toggleDetails(qr)">
          <div class="qo-item__img" v-if="qr.productPicture">
            <img :src="$blobUrl + '/' + qr.productPicture" alt="Product Image" />
          </div>
          <div class="qo-item__img qo-item__img--placeholder" v-else></div>
          <div class="qo-item__main-content">
            <div class="qo-item__name">
              <div class="qo-item__label">Product Name</div>
              <div class="qo-item__text">{{ qr.productName }}</div>
            </div>
            <div class="qo-item__name" v-if="$store.user.isSupplier">
              <div class="qo-item__label">Brand</div>
              <div class="qo-item__text">{{ qr.company.name }}</div>
            </div>
            <div class="qo-item__date">
              <div class="qo-item__label">Send out Date</div>
              <div class="qo-item__text">{{ qr.createdAt | dateformat('MM/dd/yyyy') }}</div>
            </div>
            <div class="qo-item__current-stage">
              <div class="qo-item__label">Current Stage</div>
              <div class="qo-item__text">
                {{
                  [
                    'Quotation Submitted',
                    'Global Sourcing',
                    'Suppliers Prepare Offers',
                    'FindSourcing Controlling Offers',
                    'Deal Selection',
                  ][qr.currentStage - 1]
                }}
              </div>
            </div>
            <div class="qo-item__stages">
              <div
                class="qo-item__stage"
                :class="{ 'qo-item__stage--current': qr.currentStage == 1 }"
                title="Quotation Submitted"
              >
                <img src="../img/quotation/s01.png" alt="Quotation Submitted" />
              </div>
              <div class="qo-item__stage-arrow"></div>
              <div
                class="qo-item__stage"
                :class="{ 'qo-item__stage--current': qr.currentStage == 2 }"
                title="Global Sourcing"
              >
                <img src="../img/quotation/s02.png" alt="Global Sourcing" />
              </div>
              <div class="qo-item__stage-arrow"></div>
              <div
                class="qo-item__stage"
                :class="{ 'qo-item__stage--current': qr.currentStage == 3 }"
                title="Suppliers Prepare Offers"
              >
                <img src="../img/quotation/s03.png" alt="Suppliers Prepare Offers" />
              </div>
              <div class="qo-item__stage-arrow"></div>
              <div
                class="qo-item__stage"
                :class="{ 'qo-item__stage--current': qr.currentStage == 4 }"
                title="FindSourcing Controlling Offers"
              >
                <img src="../img/quotation/s04.png" alt="FindSourcing Controlling Offers" />
              </div>
              <div class="qo-item__stage-arrow"></div>
              <div
                class="qo-item__stage"
                :class="{ 'qo-item__stage--current': qr.currentStage == 5 }"
                title="Deal Selection"
              >
                <img src="../img/quotation/s05.png" alt="Deal Selection" />
              </div>
            </div>
          </div>
          <div class="qo-item__arrow"></div>
        </div>
        <div class="qo-item__details" v-if="qr.id == detailsId">
          <div class="qo-item__tabs" v-if="qr.currentStage >= 2 && qr.quotations.length">
            <span
              class="qo-item__tab"
              :class="{ 'qo-item__tab--active': detailsTab == 0 }"
              @click="detailsTab = 0"
              v-if="$store.user.isBuyer"
            >
              Overview
            </span>
            <span
              class="qo-item__tab"
              :class="{ 'qo-item__tab--active': detailsTab == 0 }"
              @click="detailsTab = 0"
              v-if="$store.user.isSupplier"
            >
              Brand Intro
            </span>
            <span
              class="qo-item__tab"
              :class="{ 'qo-item__tab--active': detailsTab == 1 }"
              @click="detailsTab = 1"
              v-if="$store.user.isSupplier"
            >
              Quotation Info
            </span>
            <span class="qo-item__tab" :class="{ 'qo-item__tab--active': detailsTab == 2 }" @click="detailsTab = 2">
              {{ $store.user.isBuyer ? 'Summary' : 'Submitted offer' }}
            </span>
          </div>
          <form @submit.prevent="submitQuotationRequest" v-if="detailsTab == 0 && $store.user.isBuyer">
            <div class="qo-item__container">
              <div class="qo-item__info">
                <div class="qo-item__info-title">Product Description</div>
                <div class="qo-item__info-data qo-item__info-data--grid">
                  <div class="quotation__control">
                    <label class="quotation__label">
                      <div class="quotation__label-text">
                        Product Name <span class="quotation__required" v-if="enableEditDetails">*</span>
                      </div>
                      <input
                        type="text"
                        v-model.trim="detailsData.productName"
                        required="required"
                        class="quotation__input"
                        :disabled="!enableEditDetails"
                      />
                    </label>
                  </div>

                  <div class="quotation__control">
                    <div class="quotation__label">
                      <div class="quotation__label-text">
                        <span v-if="enableEditDetails">Upload</span> Design Sketch
                        <span class="quotation__required" v-if="enableEditDetails">*</span>
                      </div>
                      <div class="quotation__file" v-if="detailsData.productSketch">
                        <a
                          class="quotation__file-link"
                          :href="$blobUrl + '/' + detailsData.productSketch"
                          target="_blank"
                        >
                          {{ detailsData.productSketch.split('/')[1] }}
                        </a>
                        <span
                          class="quotation__file-delete"
                          title="Delete file"
                          @click="detailsData.productSketch = null"
                          v-if="enableEditDetails"
                        ></span>
                      </div>
                      <input
                        v-else
                        type="file"
                        class="quotation__input-file"
                        data-target="detailsDesignSketch"
                        @change="onFileChange"
                        accept="image/*,.pdf"
                        required
                      />
                    </div>
                  </div>

                  <div class="quotation__control">
                    <label class="quotation__label">
                      <div class="quotation__label-text">
                        Product Type <span class="quotation__required" v-if="enableEditDetails">*</span>
                      </div>
                      <select
                        class="quotation__select"
                        :class="{ 'quotation__select--placeholder': !detailsData.productType }"
                        v-model="detailsData.productType"
                        required
                        :disabled="!enableEditDetails"
                      >
                        <option value="" disabled></option>
                        <option v-for="(ft, i) in footwearTypes" :value="ft.title" :key="i">{{ ft.title }}</option>
                      </select>
                    </label>
                  </div>

                  <div class="quotation__control">
                    <label class="quotation__label">
                      <div class="quotation__label-text">Production Process</div>
                      <select
                        class="quotation__select"
                        :class="{ 'quotation__select--placeholder': !detailsData.productProductionProcess }"
                        v-model="detailsData.productProductionProcess"
                        :disabled="!enableEditDetails"
                      >
                        <option value=""></option>
                        <option v-for="(process, i) in processes" :value="process.title" :key="i">
                          {{ process.title }}
                        </option>
                      </select>
                    </label>
                  </div>

                  <div class="quotation__control">
                    <label class="quotation__label">
                      <div class="quotation__label-text">Materials Upper</div>
                      <input
                        type="text"
                        class="quotation__input"
                        v-model.trim="detailsData.productMaterialsUpper"
                        :disabled="!enableEditDetails"
                      />
                    </label>
                  </div>

                  <div class="quotation__control">
                    <label class="quotation__label">
                      <div class="quotation__label-text">Materials Sole</div>
                      <input
                        type="text"
                        class="quotation__input"
                        v-model.trim="detailsData.productMaterialsSole"
                        :disabled="!enableEditDetails"
                      />
                    </label>
                  </div>

                  <div class="quotation__control">
                    <label class="quotation__label">
                      <div class="quotation__label-text">Prefered Country/Region</div>
                      <select
                        v-model="detailsData.preferedRegion"
                        class="quotation__select"
                        :disabled="!enableEditDetails"
                      >
                        <option value=""></option>
                        <option disabled class="quotation__select-separator">────────────────────</option>
                        <option value="Europe">Europe</option>
                        <option value="Middle East">Middle East</option>
                        <option value="Asia">Asia</option>
                        <option value="Africa">Africa</option>
                        <option value="North America">North America</option>
                        <option value="South America">South America</option>
                        <option value="Oceania">Oceania</option>
                        <option disabled class="quotation__select-separator">────────────────────</option>
                        <option v-for="(country, i) in $store.countries.all" :value="country" :key="i">
                          {{ country }}
                        </option>
                      </select>
                    </label>
                  </div>

                  <div class="quotation__control">
                    <label class="quotation__label">
                      <div class="quotation__label-text">Existing Relationships</div>
                      <input
                        type="text"
                        class="quotation__input"
                        v-model.trim="detailsData.existingRelationships"
                        :disabled="!enableEditDetails"
                      />
                    </label>
                  </div>

                  <div class="quotation__control" style="width: 100%; padding-right: 0">
                    <label class="quotation__label">
                      <div class="quotation__label-text">
                        Product Description <span class="quotation__required" v-if="enableEditDetails">*</span>
                      </div>
                      <input
                        class="quotation__input"
                        v-model.trim="detailsData.productDescription"
                        required
                        v-if="enableEditDetails"
                      />
                      <div class="quotation__input quotation__input--disabled" style="height: auto" v-else>
                        {{ detailsData.productDescription || '—' }}
                      </div>
                    </label>
                  </div>

                  <div class="quotation__control" style="width: 100%; padding-left: 0">
                    <div class="quotation__label-text">Product Focus</div>
                    <QuotationProductFocus
                      :quality="+detailsData.productFocusQuality"
                      :price="+detailsData.productFocusPrice"
                      :speed="+detailsData.productFocusSpeed"
                      :disabled="!enableEditDetails"
                      @change="productFocusChange"
                    >
                    </QuotationProductFocus>
                  </div>
                </div>
              </div>
              <div class="qo-item__info">
                <div class="qo-item__info-title">Quotation Information</div>
                <div class="qo-item__info-data">
                  <div class="quotation__control">
                    <label class="quotation__label">
                      <div class="quotation__label-text">
                        Planned Order Quantity by Article
                        <span class="quotation__required" v-if="enableEditDetails">*</span>
                      </div>
                      <input
                        type="number"
                        min="1"
                        class="quotation__input"
                        v-model.trim="detailsData.qiPlannedOrderQuantity"
                        required
                        :disabled="!enableEditDetails"
                      />
                    </label>
                  </div>

                  <div class="quotation__control">
                    <label class="quotation__label">
                      <div class="quotation__label-text">Planned Articles</div>
                      <input
                        type="number"
                        min="1"
                        class="quotation__input"
                        v-model.trim="detailsData.qiPlannedArticles"
                        :disabled="!enableEditDetails"
                      />
                    </label>
                  </div>

                  <div class="quotation__control">
                    <label class="quotation__label">
                      <div class="quotation__label-text">Total Quantity</div>
                      <input
                        type="number"
                        min="1"
                        class="quotation__input"
                        v-model.trim="detailsData.qiTotalQuantity"
                        :disabled="!enableEditDetails"
                      />
                    </label>
                  </div>

                  <div class="quotation__control">
                    <label class="quotation__label">
                      <div class="quotation__label-text">Target price FOB/FCA</div>
                      <input
                        type="text"
                        class="quotation__input"
                        v-model.trim="detailsData.qiTargetPrice"
                        :disabled="!enableEditDetails"
                      />
                    </label>
                  </div>

                  <div class="quotation__control">
                    <label class="quotation__label">
                      <div class="quotation__label-text">Size Range</div>
                      <strong>{{ detailsData.qiSizeRangeFrom }} - {{ detailsData.qiSizeRangeTo }}</strong>
                      <vue-slider
                        class="supplier__slider-slider"
                        :disabled="!enableEditDetails"
                        :value="[detailsData.qiSizeRangeFrom, detailsData.qiSizeRangeTo]"
                        :min="11"
                        :max="55"
                        :width="'100%'"
                        :tooltip="false"
                        :bgStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.13)' }"
                        :sliderStyle="{ backgroundColor: '#fff', border: '2px solid #37495e' }"
                        :processStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.8)' }"
                        @callback="watchSizeRange"
                      >
                      </vue-slider>
                    </label>
                  </div>

                  <div class="quotation__control">
                    <label class="quotation__label">
                      <div class="quotation__label-text">Packaging Cost</div>
                      <input
                        type="text"
                        class="quotation__input"
                        v-model.trim="detailsData.qiPackagingCost"
                        :disabled="!enableEditDetails"
                      />
                    </label>
                  </div>

                  <div class="quotation__control">
                    <label class="quotation__label">
                      <div class="quotation__label-text">Shipping Destination</div>
                      <input
                        class="quotation__input"
                        v-model.trim="detailsData.qiShippingDestination"
                        :disabled="!enableEditDetails"
                      />
                    </label>
                  </div>

                  <div class="quotation__control">
                    <label class="quotation__label">
                      <div class="quotation__label-text">Date to Receive Goods</div>
                      <input
                        class="quotation__input"
                        v-model.trim="detailsData.qiDateToReceiveGoods"
                        :disabled="!enableEditDetails"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div class="qo-item__info qo-item__info--small">
                <div class="qo-item__info-title">Log About Quotation</div>
                <div class="qo-item__info-data qo-item__info-data--pre">
                  <p>{{ qr.log || '—' }}</p>
                </div>
              </div>
            </div>
            <div class="qo-item__footer" v-if="enableEditDetails">
              <button type="submit" class="quotation__submit">Update Information</button>
            </div>
          </form>
          <div v-if="detailsTab == 0 && $store.user.isSupplier" style="padding: 0 20px">
            <quotation-info-brand :quotationRequest="detailsData"></quotation-info-brand>
          </div>
          <div v-if="detailsTab == 1 && $store.user.isSupplier" style="padding: 0 20px">
            <quotation-info :quotationRequest="detailsData"></quotation-info>
          </div>
          <div class="qo-results" v-if="detailsTab == 2">
            <QuotationOverviewResult
              class="qo-result"
              v-for="(q, i) in qr.quotations"
              :quotation="q"
              :key="i"
              :fromAdminApp="false"
              @update="updateQuotation"
              v-if="$store.user.isBuyer"
            />
            <QuotationOffer
              :quotation="qr.quotations[0]"
              mode="update"
              :stage="detailsData.currentStage"
              @update="updateQuotation"
              v-if="$store.user.isSupplier"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import QuotationProductFocus from './quotation-product-focus.vue';
import QuotationOverviewResult from './quotation-overview-result.vue';
import QuotationInfoBrand from './quotation-info-brand-intro.vue';
import QuotationInfo from './quotation-info-quotation.vue';
import QuotationOffer from './quotation-offer.vue';

import VueSlider from 'vue-slider-component/src/vue2-slider.vue';

export default {
  name: 'quotation-overview',
  load({ store, route }) {
    store.head.title = `FindSourcing – Quotation Overview`;

    return Promise.all([store.currency.loadMainCurrencies(), store.quotations.loadRequests()]);
  },
  components: {
    QuotationProductFocus,
    QuotationOverviewResult,
    QuotationOffer,
    VueSlider,
    QuotationInfoBrand,
    QuotationInfo,
  },
  data() {
    return {
      detailsId: null,
      detailsData: {},
      detailsTab: 0,
      loadingFiles: [],
      detailsDesignSketch: null,
    };
  },
  computed: {
    quotationRequests() {
      return this.$store.quotations.requests || [];
    },
    footwearTypes() {
      return this.$store.categories.all.find((c) => c.slug == 'footwear-types').articles;
    },
    processes() {
      return this.$store.categories.all.find((c) => c.slug == 'construction').articles;
    },
    enableEditDetails() {
      return this.detailsData.currentStage < 3 && this.$store.user.isBuyer;
    },
    quotationRequestsWithQuotation() {
      return this.$store.user.isBuyer
        ? this.quotationRequests
        : this.quotationRequests.filter(
            (qr) => qr.quotations.filter((q) => (q.supplier || {}).id == (this.$store.user.company || {}).id).length
          ); // Need to make sure $store.user.company is actually set, since it can cause errors when logging out otherwise
    },
    quotationRequestsWithoutQuotation() {
      return this.quotationRequests.filter((qr) => this.quotationRequestsWithQuotation.indexOf(qr) < 0);
    },
  },
  methods: {
    invalidHandler(e) {
      e.target.classList.add('invalid');
      // Scroll to first invalid field
      let counter = 0;
      let el = document.querySelector('.invalid');
      let offsetTop = el.offsetTop - document.querySelector('.header').clientHeight - 50;
      window.scrollTo(0, offsetTop);
    },
    fileToData(file) {
      if (!file) return null;
      let formData = new FormData();
      formData.append('file', file);
      return formData;
    },
    onFileChange(e) {
      const input = e.target;
      const { target } = input.dataset;
      if (input.files && input.files[0]) {
        const file = this.fileToData(input.files[0]);
        this[target] = file;
      } else {
        this[target] = null;
      }
    },
    async loadFile(file, obj, ref) {
      const { file: img } = await this.$store.companies.uploadFile(file);

      this[obj][ref] = img;
    },
    toggleDetails(qr) {
      if (this.detailsId == qr.id) {
        this.detailsId = null;
        this.detailsData = null;
        this.detailsDesignSketch = null;
        this.detailsTab = 0;
      } else {
        this.detailsId = qr.id;
        this.detailsData = JSON.parse(JSON.stringify(qr));
        this.detailsDesignSketch = null;
        this.detailsTab = qr.currentStage >= 3 && qr.quotations.length ? 2 : 0;
        if (!this.detailsData.qiSizeRangeFrom) this.detailsData.qiSizeRangeFrom = 11;
        if (!this.detailsData.qiSizeRangeTo) this.detailsData.qiSizeRangeTo = 55;
        setTimeout(() => {
          this.$scrollTo(`#qoItem${qr.id}`, 500, { offset: -document.querySelector('.header').clientHeight - 10 });
        }, 100);
      }
    },
    gotoIntro(qr) {
      this.$router.push({
        name: 'quotationInfo',
        params: { id: qr.id },
      });
    },
    productFocusChange(values) {
      this.detailsData.productFocusQuality = values.quality;
      this.detailsData.productFocusSpeed = values.speed;
      this.detailsData.productFocusPrice = values.price;
    },
    async submitQuotationRequest() {
      if (!this.enableEditDetails) return;
      this.$store.general.loading = true;

      if (this.detailsDesignSketch) {
        this.loadingFiles.push(this.loadFile(this.detailsDesignSketch, 'detailsData', 'productSketch'));
        await Promise.all(this.loadingFiles);
        this.loadingFiles = [];
      }

      this.saveQuotationRequest();
    },
    async saveQuotationRequest() {
      await this.$store.quotations.updateRequest(this.detailsData);

      this.$store.general.loading = false;
      this.$notify({
        type: 'success',
        title: 'Information successfully updated!',
      });
    },
    updateQuotation(status) {
      this.$store.quotations.loadRequests().then((res) => {
        this.$store.general.loading = false;
        if (status) {
          this.$notify({
            type: 'success',
            title: 'Thanks, an introduction will be made within 24 hours.',
          });
        }
      });
    },
    watchSizeRange(value) {
      (this.detailsData.qiSizeRangeFrom = value[0]), (this.detailsData.qiSizeRangeTo = value[1]);
    },
  },
  mounted() {
    document.addEventListener('invalid', this.invalidHandler, true);
    // Toggle qr if route hash
    let qr = this.quotationRequests.filter((qr) => qr.id == this.$route.hash.substring(1))[0];
    if (qr) this.toggleDetails(qr);
  },
  beforeDestroy() {
    document.removeEventListener('invalid', this.invalidHandler, true);
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
.svg-icons {
  display: none;
}
.qo {
  &__button {
    max-width: math.div(30rem, 1.6);
    margin: 0 auto math.div(5rem, 1.6);
  }
  &-list {
    max-width: math.div(130rem, 1.6);
    margin: math.div(3rem, 1.6) auto;
  }
  &-item {
    border: 1px solid rgba($c-dark, 0.2);
    transition: border-color 0.5s ease;
    &:hover {
      border-color: rgba($c-dark, 0.4);
    }
    &:not(:first-child) {
      margin-top: math.div(1rem, 1.6);
    }
    &__arrow {
      flex-shrink: 0;
      display: block;
      width: math.div(1rem, 1.6);
      height: math.div(1rem, 1.6);
      margin: math.div(-0.5rem, 1.6) math.div(2rem, 1.6) 0;
      border-top: 2px solid rgba($c-dark, 0.5);
      border-right: 2px solid rgba($c-dark, 0.5);
      transform: rotate(135deg);

      &--right {
        transform: rotate(45deg);
      }
    }
    &.active {
      border: 1px solid rgba($c-dark, 0.2);
    }
    &.active &__arrow {
      transform: rotate(-45deg);
      margin-top: math.div(0.5rem, 1.6);
    }
    &__main {
      display: flex;
      align-items: center;
      cursor: pointer;
      &-content {
        width: 100%;
        @include tablet-min {
          display: flex;
          align-items: center;
        }
      }
    }
    &__img {
      display: none;
      @include tablet-min {
        display: block;
        width: math.div(16rem, 1.6);
        flex-shrink: 0;
        padding: math.div(0.5rem, 1.6);
        &--placeholder {
          &:before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 66%;
            background: $c-light-gray;
          }
        }
        img {
          max-width: math.div(15rem, 1.6);
          max-height: math.div(15rem, 1.6);
        }
      }
    }
    &__label {
      font-size: math.div(1.1rem, 1.6);
      text-transform: uppercase;
      opacity: 0.7;
      line-height: math.div(1.3rem, 1.6);
      &--h {
        height: math.div(3rem, 1.6);
      }
    }
    &__text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &--l {
        font-size: math.div(1.8rem, 1.6);
      }
    }
    &__name {
      width: math.div(20rem, 1.6);
      flex-shrink: 0;
      padding: math.div(1rem, 1.6);
    }
    &__date {
      width: math.div(12rem, 1.6);
      flex-shrink: 0;
      padding: math.div(1rem, 1.6);
    }

    &__current-stage {
      padding: math.div(1rem, 1.6);
      @include tablet-landscape-min {
        display: none;
      }
    }

    &__stages {
      display: none;
      @include tablet-landscape-min {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-grow: 1;
      }
    }
    &__stage {
      padding: math.div(1rem, 1.6);
      opacity: 0.3;
      &--current {
        opacity: 1;
      }
      img {
        max-height: math.div(5rem, 1.6);
      }
      &-arrow {
        width: math.div(2.8rem, 1.6);
        height: math.div(0.8rem, 1.6);
        background: url('../img/quotation/arrow.png') no-repeat 50% 50%;
        background-size: contain;
      }
    }

    &__tabs {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 0 math.div(1.5rem, 1.6);
      border-bottom: 1px solid rgba($c-dark, 0.1);
    }
    &__tab {
      display: block;
      padding: math.div(1.5rem, 1.6);
      margin-bottom: -1px;
      cursor: pointer;
      &--active {
        color: $c-blue;
        border-bottom: 1px solid $c-blue;
      }
    }

    &__details {
      border-top: 1px solid rgba($c-dark, 0.1);
    }
    &__container {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      @include tablet-landscape-min {
        flex-wrap: nowrap;
        padding: 0 math.div(2rem, 1.6);
      }
    }
    &__info {
      width: 100%;
      padding: math.div(1rem, 1.6);
      @include tablet-min {
        width: 50%;
        &:nth-child(2) {
          border-left: 1px solid rgba($c-dark, 0.1);
        }
        &--small {
          width: 100%;
          border-top: 1px solid rgba($c-dark, 0.1);
        }
      }
      @include tablet-landscape-min {
        padding: math.div(2rem, 1.6);
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &--small {
          width: 30%;
        }
        &:not(:first-child) {
          border-top: 0;
          border-left: 1px solid rgba($c-dark, 0.1);
        }
      }
      &-title {
        font-size: math.div(1.4rem, 1.6);
        text-transform: uppercase;
        opacity: 0.7;
      }
      &-data {
        &--grid {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          & > * {
            width: 100%;
            @include mobile-ls-min {
              width: 50%;
              &:nth-child(odd) {
                padding-right: math.div(1rem, 1.6);
              }
              &:nth-child(even) {
                padding-left: math.div(1rem, 1.6);
              }
            }
          }
        }
        &--pre {
          white-space: pre-wrap;
        }
      }
    }
    &__footer {
      padding: math.div(2rem, 1.6);
      border-top: 1px solid rgba($c-dark, 0.1);
      text-align: center;
    }
  }

  &-results {
    padding: math.div(1rem, 1.6);
    @include tablet-min {
      display: flex;
      flex-wrap: wrap;
      padding: math.div(1.5rem, 1.6);
    }
  }
  &-result {
    border: 1px solid rgba($c-dark, 0.1);
    @include mobile-only {
      max-width: math.div(40rem, 1.6);
      margin-left: auto;
      margin-right: auto;
      &:nth-child(n + 2) {
        margin-top: math.div(1rem, 1.6);
      }
    }
    @include tablet-min {
      max-width: 100%;
    }
    @include tablet-only {
      width: calc(50% - #{math.div(1.5rem, 1.6)});
      &:not(:nth-child(2n + 1)) {
        margin-left: math.div(1.5rem, 1.6);
      }
      &:nth-child(n + 3) {
        margin-top: math.div(1.5rem, 1.6);
      }
    }
    @include desktop-min {
      width: calc(33.333333% - #{math.div(1rem, 1.6)});
      &:not(:nth-child(3n + 1)) {
        margin-left: math.div(1.5rem, 1.6);
      }
      &:nth-child(n + 4) {
        margin-top: math.div(1.5rem, 1.6);
      }
    }
  }
}
</style>
