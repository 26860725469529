var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "page" }, [
    _c("h1", { staticClass: "page__heading" }, [_vm._v("Quotation Overview")]),
    _vm._v(" "),
    _vm.$store.user.isBuyer
      ? _c(
          "p",
          {
            staticClass:
              "post-content__highlighted post-content__highlighted--small",
          },
          [
            _vm._v(
              "\n    Below you can see your pending quotation requests and make new ones.\n  "
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$store.user.isSupplier
      ? _c(
          "p",
          {
            staticClass:
              "post-content__highlighted post-content__highlighted--small",
          },
          [
            _vm._v(
              "\n    Below you can see the quotations you have submitted with possibility to update them.\n  "
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$store.user.isBuyer
      ? _c(
          "p",
          [
            _c(
              "router-link",
              {
                staticClass: "qo__button old-form__button",
                attrs: { to: { name: "quotationForm" } },
              },
              [_vm._v("Make new quotation")]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.quotationRequestsWithoutQuotation.length
      ? _c(
          "h2",
          {
            staticClass:
              "page__subheading post-content__subheading post-content__subheading--center",
          },
          [_vm._v("\n    New quotation requests\n  ")]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.quotationRequests.length
      ? _c(
          "h2",
          {
            staticClass:
              "page__subheading post-content__subheading post-content__subheading--center",
          },
          [_vm._v("\n    No Quotations Pending\n  ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.quotationRequestsWithoutQuotation.length
      ? _c(
          "div",
          { staticClass: "qo-list" },
          _vm._l(_vm.quotationRequestsWithoutQuotation, function (qr, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "qo-item",
                class: {
                  active: _vm.detailsData && _vm.detailsData.id == qr.id,
                },
                attrs: { id: "qoItem" + qr.id },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "qo-item__main",
                    on: {
                      click: function ($event) {
                        return _vm.gotoIntro(qr)
                      },
                    },
                  },
                  [
                    qr.productPicture
                      ? _c("div", { staticClass: "qo-item__img" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.$blobUrl + "/" + qr.productPicture,
                              alt: "Product Image",
                            },
                          }),
                        ])
                      : _c("div", {
                          staticClass: "qo-item__img qo-item__img--placeholder",
                        }),
                    _vm._v(" "),
                    _c("div", { staticClass: "qo-item__main-content" }, [
                      _c("div", { staticClass: "qo-item__name" }, [
                        _c("div", { staticClass: "qo-item__label" }, [
                          _vm._v("Product Name"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "qo-item__text" }, [
                          _vm._v(_vm._s(qr.productName)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.$store.user.isSupplier
                        ? _c("div", { staticClass: "qo-item__name" }, [
                            _c("div", { staticClass: "qo-item__label" }, [
                              _vm._v("Brand"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "qo-item__text" }, [
                              _vm._v(_vm._s(qr.company.name)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "qo-item__date" }, [
                        _c("div", { staticClass: "qo-item__label" }, [
                          _vm._v("Send out Date"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "qo-item__text" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateformat")(qr.createdAt, "MM/dd/yyyy")
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "qo-item__current-stage" }, [
                        _c("div", { staticClass: "qo-item__label" }, [
                          _vm._v("Current Stage"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "qo-item__text" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                [
                                  "Quotation Submitted",
                                  "Global Sourcing",
                                  "Suppliers Prepare Offers",
                                  "FindSourcing Controlling Offers",
                                  "Deal Selection",
                                ][qr.currentStage - 1]
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "qo-item__stages" }, [
                        _c(
                          "div",
                          {
                            staticClass: "qo-item__stage",
                            class: {
                              "qo-item__stage--current": qr.currentStage == 1,
                            },
                            attrs: { title: "Quotation Submitted" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../img/quotation/s01.png"),
                                alt: "Quotation Submitted",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "qo-item__stage-arrow" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "qo-item__stage",
                            class: {
                              "qo-item__stage--current": qr.currentStage == 2,
                            },
                            attrs: { title: "Global Sourcing" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../img/quotation/s02.png"),
                                alt: "Global Sourcing",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "qo-item__stage-arrow" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "qo-item__stage",
                            class: {
                              "qo-item__stage--current": qr.currentStage == 3,
                            },
                            attrs: { title: "Suppliers Prepare Offers" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../img/quotation/s03.png"),
                                alt: "Suppliers Prepare Offers",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "qo-item__stage-arrow" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "qo-item__stage",
                            class: {
                              "qo-item__stage--current": qr.currentStage == 4,
                            },
                            attrs: { title: "FindSourcing Controlling Offers" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../img/quotation/s04.png"),
                                alt: "FindSourcing Controlling Offers",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "qo-item__stage-arrow" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "qo-item__stage",
                            class: {
                              "qo-item__stage--current": qr.currentStage == 5,
                            },
                            attrs: { title: "Deal Selection" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../img/quotation/s05.png"),
                                alt: "Deal Selection",
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "qo-item__arrow qo-item__arrow--right",
                    }),
                  ]
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.quotationRequestsWithQuotation.length
      ? _c(
          "h2",
          {
            staticClass:
              "page__subheading post-content__subheading post-content__subheading--center",
          },
          [_vm._v("\n    Submitted quotations\n  ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.quotationRequestsWithQuotation.length
      ? _c(
          "div",
          { staticClass: "qo-list" },
          _vm._l(_vm.quotationRequestsWithQuotation, function (qr, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "qo-item",
                class: {
                  active: _vm.detailsData && _vm.detailsData.id == qr.id,
                },
                attrs: { id: "qoItem" + qr.id },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "qo-item__main",
                    on: {
                      click: function ($event) {
                        return _vm.toggleDetails(qr)
                      },
                    },
                  },
                  [
                    qr.productPicture
                      ? _c("div", { staticClass: "qo-item__img" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.$blobUrl + "/" + qr.productPicture,
                              alt: "Product Image",
                            },
                          }),
                        ])
                      : _c("div", {
                          staticClass: "qo-item__img qo-item__img--placeholder",
                        }),
                    _vm._v(" "),
                    _c("div", { staticClass: "qo-item__main-content" }, [
                      _c("div", { staticClass: "qo-item__name" }, [
                        _c("div", { staticClass: "qo-item__label" }, [
                          _vm._v("Product Name"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "qo-item__text" }, [
                          _vm._v(_vm._s(qr.productName)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.$store.user.isSupplier
                        ? _c("div", { staticClass: "qo-item__name" }, [
                            _c("div", { staticClass: "qo-item__label" }, [
                              _vm._v("Brand"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "qo-item__text" }, [
                              _vm._v(_vm._s(qr.company.name)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "qo-item__date" }, [
                        _c("div", { staticClass: "qo-item__label" }, [
                          _vm._v("Send out Date"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "qo-item__text" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateformat")(qr.createdAt, "MM/dd/yyyy")
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "qo-item__current-stage" }, [
                        _c("div", { staticClass: "qo-item__label" }, [
                          _vm._v("Current Stage"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "qo-item__text" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                [
                                  "Quotation Submitted",
                                  "Global Sourcing",
                                  "Suppliers Prepare Offers",
                                  "FindSourcing Controlling Offers",
                                  "Deal Selection",
                                ][qr.currentStage - 1]
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "qo-item__stages" }, [
                        _c(
                          "div",
                          {
                            staticClass: "qo-item__stage",
                            class: {
                              "qo-item__stage--current": qr.currentStage == 1,
                            },
                            attrs: { title: "Quotation Submitted" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../img/quotation/s01.png"),
                                alt: "Quotation Submitted",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "qo-item__stage-arrow" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "qo-item__stage",
                            class: {
                              "qo-item__stage--current": qr.currentStage == 2,
                            },
                            attrs: { title: "Global Sourcing" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../img/quotation/s02.png"),
                                alt: "Global Sourcing",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "qo-item__stage-arrow" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "qo-item__stage",
                            class: {
                              "qo-item__stage--current": qr.currentStage == 3,
                            },
                            attrs: { title: "Suppliers Prepare Offers" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../img/quotation/s03.png"),
                                alt: "Suppliers Prepare Offers",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "qo-item__stage-arrow" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "qo-item__stage",
                            class: {
                              "qo-item__stage--current": qr.currentStage == 4,
                            },
                            attrs: { title: "FindSourcing Controlling Offers" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../img/quotation/s04.png"),
                                alt: "FindSourcing Controlling Offers",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "qo-item__stage-arrow" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "qo-item__stage",
                            class: {
                              "qo-item__stage--current": qr.currentStage == 5,
                            },
                            attrs: { title: "Deal Selection" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../img/quotation/s05.png"),
                                alt: "Deal Selection",
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "qo-item__arrow" }),
                  ]
                ),
                _vm._v(" "),
                qr.id == _vm.detailsId
                  ? _c("div", { staticClass: "qo-item__details" }, [
                      qr.currentStage >= 2 && qr.quotations.length
                        ? _c("div", { staticClass: "qo-item__tabs" }, [
                            _vm.$store.user.isBuyer
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "qo-item__tab",
                                    class: {
                                      "qo-item__tab--active":
                                        _vm.detailsTab == 0,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.detailsTab = 0
                                      },
                                    },
                                  },
                                  [_vm._v("\n            Overview\n          ")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.$store.user.isSupplier
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "qo-item__tab",
                                    class: {
                                      "qo-item__tab--active":
                                        _vm.detailsTab == 0,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.detailsTab = 0
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            Brand Intro\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.$store.user.isSupplier
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "qo-item__tab",
                                    class: {
                                      "qo-item__tab--active":
                                        _vm.detailsTab == 1,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.detailsTab = 1
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            Quotation Info\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "qo-item__tab",
                                class: {
                                  "qo-item__tab--active": _vm.detailsTab == 2,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.detailsTab = 2
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$store.user.isBuyer
                                        ? "Summary"
                                        : "Submitted offer"
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.detailsTab == 0 && _vm.$store.user.isBuyer
                        ? _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submitQuotationRequest($event)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "qo-item__container" }, [
                                _c("div", { staticClass: "qo-item__info" }, [
                                  _c(
                                    "div",
                                    { staticClass: "qo-item__info-title" },
                                    [_vm._v("Product Description")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "qo-item__info-data qo-item__info-data--grid",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "quotation__control" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Product Name "
                                                  ),
                                                  _vm.enableEditDetails
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "quotation__required",
                                                        },
                                                        [_vm._v("*")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value:
                                                      _vm.detailsData
                                                        .productName,
                                                    expression:
                                                      "detailsData.productName",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                staticClass: "quotation__input",
                                                attrs: {
                                                  type: "text",
                                                  required: "required",
                                                  disabled:
                                                    !_vm.enableEditDetails,
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.detailsData.productName,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.detailsData,
                                                      "productName",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "quotation__control" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [
                                                  _vm.enableEditDetails
                                                    ? _c("span", [
                                                        _vm._v("Upload"),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(
                                                    " Design Sketch\n                      "
                                                  ),
                                                  _vm.enableEditDetails
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "quotation__required",
                                                        },
                                                        [_vm._v("*")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.detailsData.productSketch
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "quotation__file",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "quotation__file-link",
                                                          attrs: {
                                                            href:
                                                              _vm.$blobUrl +
                                                              "/" +
                                                              _vm.detailsData
                                                                .productSketch,
                                                            target: "_blank",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.detailsData.productSketch.split(
                                                                  "/"
                                                                )[1]
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.enableEditDetails
                                                        ? _c("span", {
                                                            staticClass:
                                                              "quotation__file-delete",
                                                            attrs: {
                                                              title:
                                                                "Delete file",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.detailsData.productSketch =
                                                                  null
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _c("input", {
                                                    staticClass:
                                                      "quotation__input-file",
                                                    attrs: {
                                                      type: "file",
                                                      "data-target":
                                                        "detailsDesignSketch",
                                                      accept: "image/*,.pdf",
                                                      required: "",
                                                    },
                                                    on: {
                                                      change: _vm.onFileChange,
                                                    },
                                                  }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "quotation__control" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Product Type "
                                                  ),
                                                  _vm.enableEditDetails
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "quotation__required",
                                                        },
                                                        [_vm._v("*")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.detailsData
                                                          .productType,
                                                      expression:
                                                        "detailsData.productType",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "quotation__select",
                                                  class: {
                                                    "quotation__select--placeholder":
                                                      !_vm.detailsData
                                                        .productType,
                                                  },
                                                  attrs: {
                                                    required: "",
                                                    disabled:
                                                      !_vm.enableEditDetails,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.$set(
                                                        _vm.detailsData,
                                                        "productType",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("option", {
                                                    attrs: {
                                                      value: "",
                                                      disabled: "",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.footwearTypes,
                                                    function (ft, i) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: i,
                                                          domProps: {
                                                            value: ft.title,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(ft.title)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "quotation__control" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [_vm._v("Production Process")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.detailsData
                                                          .productProductionProcess,
                                                      expression:
                                                        "detailsData.productProductionProcess",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "quotation__select",
                                                  class: {
                                                    "quotation__select--placeholder":
                                                      !_vm.detailsData
                                                        .productProductionProcess,
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      !_vm.enableEditDetails,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.$set(
                                                        _vm.detailsData,
                                                        "productProductionProcess",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("option", {
                                                    attrs: { value: "" },
                                                  }),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.processes,
                                                    function (process, i) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: i,
                                                          domProps: {
                                                            value:
                                                              process.title,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                process.title
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "quotation__control" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [_vm._v("Materials Upper")]
                                              ),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value:
                                                      _vm.detailsData
                                                        .productMaterialsUpper,
                                                    expression:
                                                      "detailsData.productMaterialsUpper",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                staticClass: "quotation__input",
                                                attrs: {
                                                  type: "text",
                                                  disabled:
                                                    !_vm.enableEditDetails,
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.detailsData
                                                      .productMaterialsUpper,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.detailsData,
                                                      "productMaterialsUpper",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "quotation__control" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [_vm._v("Materials Sole")]
                                              ),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value:
                                                      _vm.detailsData
                                                        .productMaterialsSole,
                                                    expression:
                                                      "detailsData.productMaterialsSole",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                staticClass: "quotation__input",
                                                attrs: {
                                                  type: "text",
                                                  disabled:
                                                    !_vm.enableEditDetails,
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.detailsData
                                                      .productMaterialsSole,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.detailsData,
                                                      "productMaterialsSole",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "quotation__control" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [
                                                  _vm._v(
                                                    "Prefered Country/Region"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.detailsData
                                                          .preferedRegion,
                                                      expression:
                                                        "detailsData.preferedRegion",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "quotation__select",
                                                  attrs: {
                                                    disabled:
                                                      !_vm.enableEditDetails,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.$set(
                                                        _vm.detailsData,
                                                        "preferedRegion",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("option", {
                                                    attrs: { value: "" },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    {
                                                      staticClass:
                                                        "quotation__select-separator",
                                                      attrs: { disabled: "" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "────────────────────"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "Europe",
                                                      },
                                                    },
                                                    [_vm._v("Europe")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "Middle East",
                                                      },
                                                    },
                                                    [_vm._v("Middle East")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: { value: "Asia" },
                                                    },
                                                    [_vm._v("Asia")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "Africa",
                                                      },
                                                    },
                                                    [_vm._v("Africa")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "North America",
                                                      },
                                                    },
                                                    [_vm._v("North America")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "South America",
                                                      },
                                                    },
                                                    [_vm._v("South America")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "Oceania",
                                                      },
                                                    },
                                                    [_vm._v("Oceania")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    {
                                                      staticClass:
                                                        "quotation__select-separator",
                                                      attrs: { disabled: "" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "────────────────────"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.$store.countries.all,
                                                    function (country, i) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: i,
                                                          domProps: {
                                                            value: country,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(country) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "quotation__control" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [
                                                  _vm._v(
                                                    "Existing Relationships"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value:
                                                      _vm.detailsData
                                                        .existingRelationships,
                                                    expression:
                                                      "detailsData.existingRelationships",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                staticClass: "quotation__input",
                                                attrs: {
                                                  type: "text",
                                                  disabled:
                                                    !_vm.enableEditDetails,
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.detailsData
                                                      .existingRelationships,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.detailsData,
                                                      "existingRelationships",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "quotation__control",
                                          staticStyle: {
                                            width: "100%",
                                            "padding-right": "0",
                                          },
                                        },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Product Description "
                                                  ),
                                                  _vm.enableEditDetails
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "quotation__required",
                                                        },
                                                        [_vm._v("*")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.enableEditDetails
                                                ? _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model.trim",
                                                        value:
                                                          _vm.detailsData
                                                            .productDescription,
                                                        expression:
                                                          "detailsData.productDescription",
                                                        modifiers: {
                                                          trim: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass:
                                                      "quotation__input",
                                                    attrs: { required: "" },
                                                    domProps: {
                                                      value:
                                                        _vm.detailsData
                                                          .productDescription,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.detailsData,
                                                          "productDescription",
                                                          $event.target.value.trim()
                                                        )
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.$forceUpdate()
                                                      },
                                                    },
                                                  })
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "quotation__input quotation__input--disabled",
                                                      staticStyle: {
                                                        height: "auto",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.detailsData
                                                              .productDescription ||
                                                              "—"
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "quotation__control",
                                          staticStyle: {
                                            width: "100%",
                                            "padding-left": "0",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "quotation__label-text",
                                            },
                                            [_vm._v("Product Focus")]
                                          ),
                                          _vm._v(" "),
                                          _c("QuotationProductFocus", {
                                            attrs: {
                                              quality:
                                                +_vm.detailsData
                                                  .productFocusQuality,
                                              price:
                                                +_vm.detailsData
                                                  .productFocusPrice,
                                              speed:
                                                +_vm.detailsData
                                                  .productFocusSpeed,
                                              disabled: !_vm.enableEditDetails,
                                            },
                                            on: {
                                              change: _vm.productFocusChange,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "qo-item__info" }, [
                                  _c(
                                    "div",
                                    { staticClass: "qo-item__info-title" },
                                    [_vm._v("Quotation Information")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "qo-item__info-data" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "quotation__control" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Planned Order Quantity by Article\n                      "
                                                  ),
                                                  _vm.enableEditDetails
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "quotation__required",
                                                        },
                                                        [_vm._v("*")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value:
                                                      _vm.detailsData
                                                        .qiPlannedOrderQuantity,
                                                    expression:
                                                      "detailsData.qiPlannedOrderQuantity",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                staticClass: "quotation__input",
                                                attrs: {
                                                  type: "number",
                                                  min: "1",
                                                  required: "",
                                                  disabled:
                                                    !_vm.enableEditDetails,
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.detailsData
                                                      .qiPlannedOrderQuantity,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.detailsData,
                                                      "qiPlannedOrderQuantity",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "quotation__control" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [_vm._v("Planned Articles")]
                                              ),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value:
                                                      _vm.detailsData
                                                        .qiPlannedArticles,
                                                    expression:
                                                      "detailsData.qiPlannedArticles",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                staticClass: "quotation__input",
                                                attrs: {
                                                  type: "number",
                                                  min: "1",
                                                  disabled:
                                                    !_vm.enableEditDetails,
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.detailsData
                                                      .qiPlannedArticles,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.detailsData,
                                                      "qiPlannedArticles",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "quotation__control" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [_vm._v("Total Quantity")]
                                              ),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value:
                                                      _vm.detailsData
                                                        .qiTotalQuantity,
                                                    expression:
                                                      "detailsData.qiTotalQuantity",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                staticClass: "quotation__input",
                                                attrs: {
                                                  type: "number",
                                                  min: "1",
                                                  disabled:
                                                    !_vm.enableEditDetails,
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.detailsData
                                                      .qiTotalQuantity,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.detailsData,
                                                      "qiTotalQuantity",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "quotation__control" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [_vm._v("Target price FOB/FCA")]
                                              ),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value:
                                                      _vm.detailsData
                                                        .qiTargetPrice,
                                                    expression:
                                                      "detailsData.qiTargetPrice",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                staticClass: "quotation__input",
                                                attrs: {
                                                  type: "text",
                                                  disabled:
                                                    !_vm.enableEditDetails,
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.detailsData
                                                      .qiTargetPrice,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.detailsData,
                                                      "qiTargetPrice",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "quotation__control" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [_vm._v("Size Range")]
                                              ),
                                              _vm._v(" "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.detailsData
                                                      .qiSizeRangeFrom
                                                  ) +
                                                    " - " +
                                                    _vm._s(
                                                      _vm.detailsData
                                                        .qiSizeRangeTo
                                                    )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("vue-slider", {
                                                staticClass:
                                                  "supplier__slider-slider",
                                                attrs: {
                                                  disabled:
                                                    !_vm.enableEditDetails,
                                                  value: [
                                                    _vm.detailsData
                                                      .qiSizeRangeFrom,
                                                    _vm.detailsData
                                                      .qiSizeRangeTo,
                                                  ],
                                                  min: 11,
                                                  max: 55,
                                                  width: "100%",
                                                  tooltip: false,
                                                  bgStyle: {
                                                    backgroundColor:
                                                      "rgba(55, 73, 94, 0.13)",
                                                  },
                                                  sliderStyle: {
                                                    backgroundColor: "#fff",
                                                    border: "2px solid #37495e",
                                                  },
                                                  processStyle: {
                                                    backgroundColor:
                                                      "rgba(55, 73, 94, 0.8)",
                                                  },
                                                },
                                                on: {
                                                  callback: _vm.watchSizeRange,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "quotation__control" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [_vm._v("Packaging Cost")]
                                              ),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value:
                                                      _vm.detailsData
                                                        .qiPackagingCost,
                                                    expression:
                                                      "detailsData.qiPackagingCost",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                staticClass: "quotation__input",
                                                attrs: {
                                                  type: "text",
                                                  disabled:
                                                    !_vm.enableEditDetails,
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.detailsData
                                                      .qiPackagingCost,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.detailsData,
                                                      "qiPackagingCost",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "quotation__control" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [_vm._v("Shipping Destination")]
                                              ),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value:
                                                      _vm.detailsData
                                                        .qiShippingDestination,
                                                    expression:
                                                      "detailsData.qiShippingDestination",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                staticClass: "quotation__input",
                                                attrs: {
                                                  disabled:
                                                    !_vm.enableEditDetails,
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.detailsData
                                                      .qiShippingDestination,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.detailsData,
                                                      "qiShippingDestination",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "quotation__control" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "quotation__label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quotation__label-text",
                                                },
                                                [
                                                  _vm._v(
                                                    "Date to Receive Goods"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value:
                                                      _vm.detailsData
                                                        .qiDateToReceiveGoods,
                                                    expression:
                                                      "detailsData.qiDateToReceiveGoods",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                staticClass: "quotation__input",
                                                attrs: {
                                                  disabled:
                                                    !_vm.enableEditDetails,
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.detailsData
                                                      .qiDateToReceiveGoods,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.detailsData,
                                                      "qiDateToReceiveGoods",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "qo-item__info qo-item__info--small",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "qo-item__info-title" },
                                      [_vm._v("Log About Quotation")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "qo-item__info-data qo-item__info-data--pre",
                                      },
                                      [_c("p", [_vm._v(_vm._s(qr.log || "—"))])]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.enableEditDetails
                                ? _c(
                                    "div",
                                    { staticClass: "qo-item__footer" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "quotation__submit",
                                          attrs: { type: "submit" },
                                        },
                                        [_vm._v("Update Information")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.detailsTab == 0 && _vm.$store.user.isSupplier
                        ? _c(
                            "div",
                            { staticStyle: { padding: "0 20px" } },
                            [
                              _c("quotation-info-brand", {
                                attrs: { quotationRequest: _vm.detailsData },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.detailsTab == 1 && _vm.$store.user.isSupplier
                        ? _c(
                            "div",
                            { staticStyle: { padding: "0 20px" } },
                            [
                              _c("quotation-info", {
                                attrs: { quotationRequest: _vm.detailsData },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.detailsTab == 2
                        ? _c(
                            "div",
                            { staticClass: "qo-results" },
                            [
                              _vm._l(qr.quotations, function (q, i) {
                                return _vm.$store.user.isBuyer
                                  ? _c("QuotationOverviewResult", {
                                      key: i,
                                      staticClass: "qo-result",
                                      attrs: {
                                        quotation: q,
                                        fromAdminApp: false,
                                      },
                                      on: { update: _vm.updateQuotation },
                                    })
                                  : _vm._e()
                              }),
                              _vm._v(" "),
                              _vm.$store.user.isSupplier
                                ? _c("QuotationOffer", {
                                    attrs: {
                                      quotation: qr.quotations[0],
                                      mode: "update",
                                      stage: _vm.detailsData.currentStage,
                                    },
                                    on: { update: _vm.updateQuotation },
                                  })
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }