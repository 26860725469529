<template>
  <div class="qor">
    <div class="qor__supplier">
      <figure class="qor__supplier-img">
        <img :src="imagePath(`images/${quotation.supplier.coverPhoto}`, 960, 250)" />
      </figure>
      <div class="qor__supplier-info">
        <strong class="qor__supplier-title">{{ quotation.supplier.title }}</strong>
        <span v-html="svg.men" class="qor__supplier-icon" v-if="quotation.supplier.men"></span>
        <span v-html="svg.ladies" class="qor__supplier-icon" v-if="quotation.supplier.ladies"></span>
      </div>
      <div class="qor__info qor__info--grid">
        <div class="qor__info-cell">
          <div class="qor__info-label">Country</div>
          <div class="qor__info-text">{{ quotation.supplier.country }}</div>
        </div>
        <div class="qor__info-cell">
          <div class="qor__info-label">Website</div>
          <div class="qor__info-text">
            <a :href="quotation.supplier.web | fixHref" target="_blank" :title="quotation.supplier.web">{{
              quotation.supplier.web | urlHideProtocol
            }}</a>
          </div>
        </div>
        <div class="qor__info-cell">
          <div class="qor__info-label">Structure</div>
          <div class="qor__info-text">{{ quotation.supplier.companyStructure == 0 ? 'Manufacturer' : 'Agent' }}</div>
        </div>
      </div>
      <div class="qor__info">
        <div class="qor__info-label">Other Brands Producing</div>
        <div class="qor__info-text" :title="quotation.otherBrandsProducing">
          {{ quotation.otherBrandsProducing || '—' }}
        </div>
      </div>
      <div class="qor__info qor__info--center" v-if="!fromAdminApp">
        <router-link :to="{ name: 'supplier', params: { name: quotation.supplier.slug } }" class="qor__moreInfo"
          >More info</router-link
        >
      </div>
    </div>
    <div class="qor__details">
      <div class="qor__info">
        <div class="qor__info-label">Message from Company</div>
        <p class="qor__message">{{ quotation.messageFromSupplier }}</p>
      </div>
      <div class="qor__info">
        <div class="qor__info-label">FindSourcing Comment</div>
        <p class="qor__message">{{ quotation.messageFromFindSourcing }}</p>
      </div>
      <div class="qor__info qor__info--grid">
        <div class="qor__info-cell">
          <div class="qor__info-label qor__info-label--h">Sample L/T Correct material</div>
          <div class="qor__info-text qor__info-text--l">{{ quotation.sampleLTCorrectMaterial }}</div>
        </div>
        <div class="qor__info-cell">
          <div class="qor__info-label qor__info-label--h">Production L/T</div>
          <div class="qor__info-text qor__info-text--l">{{ quotation.productionLT }}</div>
        </div>
        <div class="qor__info-cell">
          <div class="qor__info-label qor__info-label--h">Price (FOB/FCA)</div>
          <div class="qor__info-text qor__info-text--l">
            {{ quotation.priceFOBFCA ? (quotation.currency || {}).symbol + quotation.priceFOBFCA : '—' }}
          </div>
        </div>
        <div class="qor__info-cell">
          <div class="qor__info-label qor__info-label--h">Sample L/T Available material</div>
          <div class="qor__info-text qor__info-text--l">{{ quotation.sampleLTAvailableMaterial }}</div>
        </div>
        <div class="qor__info-cell">
          <div class="qor__info-label qor__info-label--h">Minimum order quantity</div>
          <div class="qor__info-text qor__info-text--l">{{ quotation.moq }}</div>
        </div>
        <div class="qor__info-cell">
          <div class="qor__info-label qor__info-label--h">Sample Cost</div>
          <div class="qor__info-text qor__info-text--l">
            {{ quotation.sampleCost ? (quotation.currency || {}).symbol + quotation.sampleCost : '—' }}
          </div>
        </div>
      </div>
      <div class="qor__info" v-if="quotation.images && quotation.images.length">
        <strong>Pictures</strong>
        <div class="quotation__file" v-for="(image, i) in quotation.images" :key="i">
          <a class="quotation__file-link" :href="$blobUrl + '/' + image.url" target="_blank">
            {{ image.url.split('/')[1] }}
          </a>
        </div>
      </div>
      <div class="qor__info">
        <strong>Cost Breakdown</strong>
        <div class="qor__pricetable">
          <div class="qor__pricetable-row">
            <span>Materials</span>
            <i></i>
            <span>{{ quotation.cbMaterials ? (quotation.currency || {}).symbol + quotation.cbMaterials : '—' }}</span>
          </div>
          <div class="qor__pricetable-row">
            <span>Components</span>
            <i></i>
            <span>{{ quotation.cbComponents ? (quotation.currency || {}).symbol + quotation.cbComponents : '—' }}</span>
          </div>
          <div class="qor__pricetable-row">
            <span>Labelling & Packaging</span>
            <i></i>
            <span>{{
              quotation.cbLabellingPackaging ? (quotation.currency || {}).symbol + quotation.cbLabellingPackaging : '—'
            }}</span>
          </div>
          <div class="qor__pricetable-row">
            <span>Tooling</span>
            <i></i>
            <span>{{ quotation.cbTooling ? (quotation.currency || {}).symbol + quotation.cbTooling : '—' }}</span>
          </div>
          <div class="qor__pricetable-row">
            <span>Labor costs</span>
            <i></i>
            <span>{{ quotation.cbLaborCosts ? (quotation.currency || {}).symbol + quotation.cbLaborCosts : '—' }}</span>
          </div>
          <div class="qor__pricetable-row">
            <span>Overheads</span>
            <i></i>
            <span>{{ quotation.cbOverheads ? (quotation.currency || {}).symbol + quotation.cbOverheads : '—' }}</span>
          </div>
          <div class="qor__pricetable-row">
            <span>Profit</span>
            <i></i>
            <span>{{ quotation.cbProfit ? (quotation.currency || {}).symbol + quotation.cbProfit : '—' }}</span>
          </div>
        </div>
      </div>
      <div class="qor__info" v-if="!fromAdminApp">
        <strong v-if="quotation.response == null">Next Step</strong>
        <div class="qor__response">
          <p v-if="quotation.response == null">
            How do you want to proceed. <br />On positive response you´ll be sent the contact details and conversation
            history.
          </p>
          <button
            @click="acceptQuotation"
            :class="{ green: quotation.response == true && !quotationDeclined }"
            v-html="svg.like"
          ></button>
          <button
            @click="decline"
            :class="{ red: quotation.response == false || quotationDeclined }"
            v-html="svg.dislike"
          ></button>
        </div>
        <form class="qor__decline" v-if="quotationDeclined" @submit.prevent="declineQuotation">
          <textarea
            class="qor__decline-textarea"
            placeholder="Please state what is not attractive with this option. Your comment will be used to improve quality for your future quotations."
            v-model.trim="quotation.messageFromClient"
          ></textarea>
          <fs-button-main class="qor__decline-cancel" type="button" @click="cancelDecline">Cancel</fs-button-main>
          <fs-button-main class="qor__decline-submit" type="submit">Submit</fs-button-main>
        </form>
      </div>
      <div class="qor__info" v-if="fromAdminApp">
        <div class="qor__status">
          <div class="qor__status-updated qor__info-label">
            Response Updated
            <span class="qor__info-label--dark">{{
              quotation.clientsResponseUpdatedAt | dateformat("MM/dd/yyyy 'at' HH:mm")
            }}</span>
          </div>
          <div class="qor__status-icons">
            <div
              class="qor__status-icon"
              :class="{ green: quotation.response == true && !quotationDeclined }"
              v-html="svg.like"
            ></div>
            <div
              class="qor__status-icon"
              :class="{ red: quotation.response == false || quotationDeclined }"
              v-html="svg.dislike"
            ></div>
          </div>
        </div>
      </div>
      <div class="qor__info" v-if="fromAdminApp && quotation.response == false">{{ quotation.messageFromClient }}</div>
    </div>
  </div>
</template>

<script>
import { imagePath } from '@lib/imgproxy';

import FsButtonMain from '@components/buttons/fs-button-main.vue';

// SVG
import likeIcon from '../img/like-icon.svg';
import dislikeIcon from '../img/dislike-icon.svg';
import menIcon from '../img/male-icon.svg';
import ladiesIcon from '../img/female-icon.svg';

export default {
  props: ['quotation', 'fromAdminApp'],
  components: { FsButtonMain },
  filters: {
    fixHref: (value) => {
      if (!value) return;
      return '//' + value.replace(/^(https?:|)\/\//, '');
    },
    urlHideProtocol: (value) => {
      if (!value) return;
      return value.replace(/^(https?:|)\/\//, '');
    },
  },
  data() {
    return {
      svg: {
        like: likeIcon,
        dislike: dislikeIcon,
        men: menIcon,
        ladies: ladiesIcon,
      },
      quotationDeclined: false,
    };
  },
  methods: {
    imagePath,
    acceptQuotation() {
      this.quotationDeclined = false;
      if (this.quotation.response == true) return;
      this.quotation.response = true;
      this.quotation.messageFromClient = null;
      this.updateQuotation();
    },
    decline() {
      if (this.quotation.response == false) return;
      this.quotationDeclined = true;
    },
    cancelDecline() {
      this.quotationDeclined = false;
      this.quotation.messageFromClient = null;
    },
    declineQuotation() {
      this.quotation.response = false;
      this.quotationDeclined = false;
      this.updateQuotation();
    },
    async updateQuotation() {
      this.$store.general.loading = true;
      await this.$store.quotations.updateQuotation(this.quotation.supplier.id, this.quotation);
      this.$emit('update', this.quotation.response);
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
.qor {
  a {
    color: $c-blue;
  }
  &__supplier {
    padding: math.div(1rem, 1.6) math.div(1rem, 1.6) 0;
    background: rgba($c-light-gray, 0.5);
    &-img {
      position: relative;
      display: block;
      width: 100%;
      padding-bottom: 40%;
      margin: 0;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: $c-light;
      }
    }
    &-info {
      display: flex;
      padding: math.div(1rem, 1.6) 0;
    }
    &-title {
      width: 100%;
      line-height: math.div(1.8rem, 1.6);
      min-height: math.div(3.6rem, 1.6);
    }
    &-icon {
      width: math.div(1.5rem, 1.6);
      height: math.div(1.5rem, 1.6);
      fill: $c-dark;
      margin-left: math.div(0.5rem, 1.6);
    }
  }

  &__info {
    padding-top: math.div(1rem, 1.6);
    padding-bottom: math.div(1.5rem, 1.6);
    &:not(:first-child) {
      border-top: 1px solid rgba($c-dark, 0.1);
    }
    &--center {
      text-align: center;
    }
    &--grid {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 0;
    }
    &-cell {
      width: 30%;
      margin-top: math.div(1.5rem, 1.6);
      flex-shrink: 0;
    }
    &-label {
      font-size: math.div(1.1rem, 1.6);
      text-transform: uppercase;
      color: rgba($c-dark, 0.7);
      line-height: math.div(1.3rem, 1.6);
      &--h {
        min-height: math.div(3rem, 1.6);
      }
      &--dark {
        color: $c-dark;
        font-weight: bold;
      }
    }
    &-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &--l {
        font-size: math.div(1.8rem, 1.6);
      }
    }

    .quotation__file {
      border: none;
    }
  }
  &__message {
    margin: math.div(1rem, 1.6) 0 0;
    font-size: math.div(1.4rem, 1.6);
    line-height: 1.5;
    white-space: pre-wrap;
    @include tablet-min {
      height: math.div(8.4rem, 1.6);
      overflow: auto;
      padding-right: math.div(0.5rem, 1.6);
      &::-webkit-scrollbar {
        width: math.div(0.6rem, 1.6);
      }
      &::-webkit-scrollbar-track {
        background: $c-light-gray;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba($c-dark, 0.5);
      }
      &--small {
        height: math.div(4.2rem, 1.6);
      }
    }
  }
  &__moreInfo {
    text-transform: uppercase;
    font-size: math.div(1.2rem, 1.6);
  }

  &__details {
    padding: math.div(1rem, 1.6) math.div(1rem, 1.6) 0;
  }

  &__pricetable {
    margin-top: math.div(0.5rem, 1.6);
    &-row {
      display: flex;
      padding: math.div(0.5rem, 1.6) 0;
      span {
        flex-shrink: 0;
        white-space: nowrap;
      }
      i {
        display: block;
        margin-top: math.div(1.7rem, 1.6);
        margin-left: math.div(1rem, 1.6);
        margin-right: math.div(1rem, 1.6);
        width: 100%;
        height: 1px;
        border: none;
        border-top: 1px dotted $c-light-gray;
        background-color: $c-light;
      }
    }
  }

  &__response {
    display: flex;
    justify-content: center;
    p {
      width: 100%;
      margin: math.div(0.5rem, 1.6) math.div(1rem, 1.6) 0 0;
      font-size: math.div(1.3rem, 1.6);
    }
    button {
      flex-shrink: 0;
      padding: 0;
      border: 0;
      background: transparent;
      width: math.div(3rem, 1.6);
      height: math.div(3rem, 1.6);
      margin: math.div(0.5rem, 1.6) 0 0 math.div(0.7rem, 1.6);
      cursor: pointer;
      opacity: 0.7;
      transition: opacity 0.5s ease;
      outline: none;
      &:hover,
      &.red,
      &.green {
        opacity: 1;
      }
      &.red {
        svg {
          fill: $c-red;
        }
      }
      &.green {
        svg {
          fill: $c-green;
        }
      }
      svg {
        width: 100%;
        height: 100%;
        fill: $c-dark;
        pointer-events: none;
      }
    }
  }

  &__decline {
    padding: math.div(1rem, 1.6) 0 0;
    text-align: center;
    &-textarea {
      display: block;
      width: 100%;
      padding: math.div(1rem, 1.6);
      line-height: math.div(1.8rem, 1.6);
      height: math.div(9.4rem, 1.6);
      border: 1px solid rgba($c-dark, 0.2);
      resize: none;
    }
    &-submit,
    &-cancel {
      margin-top: math.div(1rem, 1.6);
    }
    &-cancel {
      border-color: rgba($c-dark, 0.5);
      color: rgba($c-dark, 0.5);
    }
  }

  &__status {
    &-updated {
      display: block;
      margin-bottom: math.div(1rem, 1.6);
    }
    &-icons {
      display: flex;
      justify-content: center;
    }
    &-icon {
      svg {
        width: math.div(3rem, 1.6);
        height: math.div(3rem, 1.6);
        margin: 0 math.div(0.5rem, 1.6);
        fill: rgba($c-dark, 0.2);
      }
      &.green svg {
        fill: $c-green;
      }
      &.red svg {
        fill: $c-red;
      }
    }
  }
}
</style>
